// :API_URL = 'http://31.186.83.240:1337',
// export const API_URL = 'https://api.centrumakrobatyczne.pl';
// export const V2_API_URL = 'https://v2.api.centrumakrobatyczne.pl';
export const V2_API_URL = 'https://api.v2.centrumakrobatyczne.pl';
export const LOCAL_API_URL = 'http://localhost:1111/';
export const NEW_API_URL_PROD = 'https://api.centrumakrobatyczne.pl/v2';
export const NEW_API_URL_LOCAL = 'http://schedulero.me/v2';

const getApiUrl = () => {
  if (process && process.env && process.env.GATSBY_IS_LOCAL) return LOCAL_API_URL;
  return V2_API_URL;
};

const getNewApiUrl = () => {
  if (process && process.env && process.env.GATSBY_IS_LOCAL) return NEW_API_URL_LOCAL;
  return NEW_API_URL_PROD;
};

export const API_URL = getApiUrl();
export const NEW_API_URL = getNewApiUrl();

export const COOKIES_ACCEPTED_KEY = 'cookies_accepted';

export const URLS_WITH_USER_ACTIONS = [
  'harmonogram',
  'harmonogram-vertykalna-kids',
  'podsumowanie',
];

export const PAYMENT_STATUSES = {
  NEW: 'new',
  PENDING: 'pending',
  PAID: 'paid',
  REFUNDED: 'refunded',
  CANCELED: 'canceled',
  FAILED: 'failed',
  EXPIRED: 'expired',
  UNKNOWN: 'unknown',
};

export const DAY_NAMES = ['poniedziałki', 'wtorki', 'środy', 'czwartki', 'piątki', 'soboty', 'niedziele'];
export const DAY_NAMES_SHORT = ['PON', 'WT', 'ŚR', 'CZW', 'PT', 'SOB', 'NIE'];
export const DAY_NAMES_SHORT_VALUES = DAY_NAMES_SHORT.map((day, index) => ({ value: index, label: day }));
export const MONTH_NAMES = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];

export const VOUCHERS_ERRORS = {
  'voucher.not_found': 'Kod jest nieprawidłowy',
  'voucher.wrong_client': 'Nie jesteś uprawniony/a do skorzystania z kodu',
  'voucher.used': 'Voucher został już wykorzystany',
};

export const TECHNICAL_BREAK = false;
